import { Box, Button, Card, CardContent, CardHeader, Chip, CircularProgress, Divider, FormControl, FormGroup, IconButton, Input, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Skeleton, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react"
import Layout from "../../components/shared-layout";
import Breadcrumb from "../../components/shared-breadcrumb";
import useStores from "../../hooks/use-stores"
import VmButton from "../../components/shared-button";
import VmModal from "../../components/shared-modal";
import VmTable from "../../components/shared-table";
import TabPanel from "../../components/shared-tab-panel";
import { GetCollegeId, PAGE_SIZE, ProdEnvCheck } from "../../constants/options";
import ContentLayout from "../../components/shared-content-layout";
import { t } from "i18next";
import { ITEM_PERFECT_INLINED, TD_FIRST, TD_LAST, TD_NORMAL, THEME_COLOR } from "../../constants/style";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { constructImgUrl } from "../../constants/settings";
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HistoryIcon from '@mui/icons-material/History';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import TopicIcon from '@mui/icons-material/Topic';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import PendingIcon from '@mui/icons-material/Pending';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { toJS } from "mobx";


function TabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const VisitPage = observer(() => {
  const { userStore } = useStores();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentVisit, setCurrentVisit] = useState<any>();
  const [currentLcoationId, setCurrentLcoationId] = useState<any>();

  const [tabValue, setTabValue] = useState<number>(1);
  const [modalTabValue, setModalTabValue] = useState<number>(0);
  const [historyPage, setHistoryPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  // june 
  // visit tab: fold / unfold
  const [fold, setFold] = useState<number>();

  // create visit request
  const [createVisitModal, setCreateVisitModal] = useState<boolean>(false); // modal display
  const [currentCreateVisit, setCurrentCreateVisit] = useState<any>(); // data store

  // create visit request prop
  const [createVisitProp, setCreateVisitProp] = useState({
    visitId: null,
    startTime: null,
    endTime: null,
    locationId: null
  })

  useEffect(() => {
    userStore.getVisitList(false, PAGE_SIZE, 0);
  }, []);

  const handleShowModal = (id: number, isHistory: boolean) => {
    let target: any;
    if (isHistory) target = userStore.visitHistoryList.data.find(v => v.id == id);
    else target = userStore.visitCurrentList.data.find(v => v.id == id);
    userStore.getOrganisationListbyGrouId(target.location.groupId)
    setCurrentVisit(target);
    setCurrentCreateVisit(target);
    setCreateVisitProp({ ...createVisitProp, "visitId": target.id, "startTime": target.startTime, "endTime": target.endTime, "locationId": target.location.groupId })
    setShowModal(true);
  }
  const handleCloseModal = () => {
    setCreateVisitModal(false)
    setCreateVisitProp({
      visitId: null,
      startTime: null,
      endTime: null,
      locationId: null
    })
    setShowModal(false);
    setCurrentVisit(null);
    setModalTabValue(0);
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    if (newValue == 0 && userStore.visitHistoryList.totalCount == 0) {
      // refresh visit list if history is never load
      userStore.getVisitList(true, PAGE_SIZE, (historyPage - 1) * PAGE_SIZE);
    } else if (newValue == 1 && userStore.visitCurrentList.totalCount == 0) {
      userStore.getVisitList(false, PAGE_SIZE, (currentPage - 1) * PAGE_SIZE);
    }
  };

  const handleModalChange = (event: React.SyntheticEvent, newValue: number) => {
    setModalTabValue(newValue);
  }

  // June

  // Folding Handler
  const onToggleFold = (id: number) => {
    if (fold === id) {
      setFold(0)
    } else {
      setFold(id)
    }
  }

  const statusPostHandler = (changeId: number, status: string) => {
    const res = confirm("Are you sure?")
    if (res) {
      if (status === "ACCEPTED") {
        userStore.updateChangeRequestStatus(changeId, true)
        alert(status)
        window.location.reload()
      } else if (status === "REJECTED") {
        userStore.updateChangeRequestStatus(changeId, false)
        alert(status)
        window.location.reload()
      } else {
        alert("Error: " + status)
      }
    }
  }

  const handleCloseCreateVisitModal = () => {
    setCreateVisitModal(false)
    setCreateVisitProp({
      visitId: null,
      startTime: null,
      endTime: null,
      locationId: null
    })

  }

  const handleCreateVisitModal = (id: number) => {
    let target = userStore.visitCurrentList.data.find(v => v.id == id);
    setCurrentCreateVisit(target);
    setCreateVisitProp({ ...createVisitProp, "visitId": target.id, "startTime": target.startTime, "endTime": target.endTime, "locationId": target.locationId })
    setCreateVisitModal(true);
  }

  const createVisitPostHanlder = (e: any) => {
    e.preventDefault();
    userStore.createVisitChangeRequest(createVisitProp);
    alert("submitted");
    console.log(createVisitProp);
    window.location.reload()
  }

  const createVisitPropHandler = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    //setCreateVisitProp({ ...createVisitProp, [name]: value })
    setCurrentLcoationId(value);
    console.log(createVisitProp)
  }

  const getOrgList = () => {
    userStore.getOrganisationListbyGrouId(localStorage.GroupId)
    console.log(userStore.organisationList)
  }


  return (
    <ContentLayout
      pageName={t('VISIT_HISTORY')}
      pageHeading={t('VISIT_HISTORY')}
      breadCrumb={[]}
      showHeadingButton={false}
    >
      <div className="flex justify-between mt-4 mb-3">
        <Typography variant="h5" fontWeight={'bold'} marginTop={1}
          sx={{ borderLeftWidth: 8, borderColor: 'black', height: 'fit-content', paddingLeft: 2 }}>
          {t('VISIT_LIST')}
        </Typography>
        <Tabs value={tabValue} onChange={handleChange}>
          <Tab label={t('History Visit')} {...TabProps(0)} />
          <Tab label={t('Active Visit')} {...TabProps(1)} />
        </Tabs>
      </div>

      {/* History List */}
      <TabPanel value={tabValue} index={0}>
        <VmTable
          loading={userStore.loading}
          page={historyPage}
          paginationCount={userStore.visitHistoryList.totalCount == 0 ? 1 : userStore.visitHistoryList.totalCount / PAGE_SIZE}
          onChangePagination={(event, pageNumber) => {
            setCurrentPage(pageNumber);
            userStore.getVisitList(true, PAGE_SIZE, (pageNumber - 1) * PAGE_SIZE);
          }}
          thead={["ID", t('VISIT_NAME'), t('START_TIME'), t('END_TIME'), t('STATUS'), t('ACTION')]}
          tbody={userStore.visitHistoryList.data.length > 0 ?
            userStore.visitHistoryList.data.map((visit, index) => (
              <>
                <Box marginY={1} />
                <tr key={`visit_${index}`} style={{ cursor: "pointer" }} >
                  <td className={TD_FIRST} onClick={() => onToggleFold(visit.id)}>{index + 1}</td>
                  <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.name}</td>
                  <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.startTime.split('T')[0] + " " + visit.startTime.split('T')[1]}</td>
                  <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.endTime.split('T')[0] + " " + visit.endTime.split('T')[1]}</td>
                  <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>
                    <span className="bg-red-400 rounded-2xl text-white p-2 pt-1">
                      <Typography variant="caption">{t('Checked Out by Teacher')}</Typography>
                    </span>
                  </td>
                  <td className={TD_LAST}>
                    <Button
                      variant="outlined"
                      onClick={() => handleShowModal(visit.id, true)}
                    >
                      {t('RESECHEDULE')}
                    </Button>
                  </td>
                </tr>
                <tr className={fold === visit.id ? "" : "hidden"}>
                  {/* folding or unfolding */}
                  <td colSpan={7}>
                    <div style={{ backgroundColor: "#e4f5f1", borderRadius: "5px", padding: "20px", gap: "1rem", display: "flex", justifyContent: "space-between" }}>
                      <Card style={{ padding: "0 10px", flex: ".3" }}>
                        <CardHeader />
                        <CardContent>
                          <AccountCircleIcon color="primary" sx={{ fontSize: 100 }} />
                          <Box mt={2} mb={1}>
                            <Typography fontWeight="bold" fontSize="1.2rem" mb={1}>Visit Detail</Typography>
                            <Typography mb={1}><b>Name: </b>{visit.name}</Typography>
                            <Typography mb={1}><b>Description: </b>{visit.description}</Typography>
                            <Typography mb={1}><b>Trainer Name: </b>{visit.responsibleTrainerName}</Typography>
                            <Typography mb={1}><b>Start Time: </b>{visit.finalStartTime.split("T")[0]} {visit.finalStartTime.split("T")[1].split(":")[0]}:{visit.finalStartTime.split("T")[1].split(":")[1]}</Typography>
                            <Typography mb={1}><b>End Time: </b>{visit.finalEndTime.split("T")[0]} {visit.finalEndTime.split("T")[1].split(":")[0]}:{visit.finalEndTime.split("T")[1].split(":")[1]}</Typography>
                            <Typography mb={1}><b>Student Name: </b>{visit.studentName}</Typography>
                            <Typography mb={1}><b>Student Email: </b>{visit.studentEmail}</Typography>
                            <Typography mb={1}><b>Location: </b>{visit.locationName}</Typography>
                            <Typography mb={1}><b>Traineeship Organisation Name: </b>{visit.traineeshipOrgName}</Typography>
                            <Typography mb={1}><b>Status: </b> {visit.status ? visit.status : "No status"}</Typography>
                          </Box>
                        </CardContent>
                      </Card>
                      <Card style={{ flex: ".9" }}>
                        {/* Original Time */}
                        <Box style={{ padding: "1rem 1.5rem" }}>
                          <span>
                            <AccessTimeIcon sx={{ marginRight: 1 }} color="primary" fontSize="large" />
                            <Typography style={{ display: 'inline-block', fontSize: 20 }} mt={1} mb={1}>Original Time</Typography>
                          </span>

                          <TableContainer>
                            <Table style={{ width: "50%" }}>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Date</TableCell>
                                  <TableCell>StartTime</TableCell>
                                  <TableCell>End Time</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableCell>{visit.startTime.split("T")[0]}</TableCell>
                                <TableCell>{visit.startTime.split("T")[1].split(":")[0]}:{visit.startTime.split("T")[1].split(":")[1]} </TableCell>
                                <TableCell>{visit.endTime.split("T")[1].split(":")[0]}:{visit.endTime.split("T")[1].split(":")[1]}</TableCell>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>

                        {/* Request History */}
                        <Box style={{ padding: "1rem 1.5rem" }}>
                          <Box mb={1}>
                            <span>
                              <HistoryIcon sx={{ marginRight: 1 }} color="primary" fontSize="large" />
                              <Typography style={{ display: 'inline-block', fontSize: 20 }} mt={1} mb={1}>Request History</Typography>
                            </span>
                            <TableContainer>
                              <Table>
                                <TableHead>
                                  <TableRow key="">

                                    <TableCell>Changed By</TableCell>
                                    <TableCell>Location</TableCell>
                                    <TableCell>Time</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Organisation</TableCell>
                                    <TableCell>Trainer</TableCell>
                                    <TableCell>Trainee</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {userStore.visitChangeList.length > 0 ? userStore.visitChangeList.map((visitChange: any, index: number) => (
                                        <TableRow key={index}>

                                          <TableCell>{visitChange.changedBy.split("-")[0]} - <br />{visitChange.changedBy.split("-")[1]}</TableCell>
                                          <TableCell>{visitChange.location.name}</TableCell>
                                          <TableCell>{visitChange.startTime.split("T")[0]} <br /> {visitChange.startTime.split("T")[1].split(":")[0]}:{visitChange.startTime.split("T")[1].split(":")[1]} - {visitChange.endTime.split("T")[1].split(":")[0]}:{visitChange.endTime.split("T")[1].split(":")[1]}</TableCell>
                                          <TableCell sx={{ textAlign: "center" }}>{visitChange.orgAccept === null || visitChange.trainerAccept === null || visitChange.traineeAccept === null ? <span style={{ color: "grey" }}><PendingIcon /></span> : (visitChange.orgAccept && visitChange.trainerAccept && visitChange.traineeAccept ? <span style={{ color: "green" }}><DoneIcon /></span> : <span style={{ color: "red" }}><CloseIcon /></span>)}</TableCell>
                                          <TableCell sx={{ textAlign: "center" }}>{visitChange.orgAccept !== null ?
                                            (visitChange.orgAccept ? <span style={{ color: "green" }}><DoneIcon /></span> : <span style={{ color: "red" }}><CloseIcon /></span>)
                                            : <><Button onClick={() => statusPostHandler(visitChange.id, "ACCEPTED")}>ACCEPT</Button><br /><Button color="error" onClick={() => statusPostHandler(visitChange.id, "REJECTED")}>REJECT</Button></>}</TableCell>
                                          <TableCell sx={{ textAlign: "center" }}>{visitChange.trainerAccept !== null ? (visitChange.trainerAccept ? <span style={{ color: "green" }}><DoneIcon /></span> : <span style={{ color: "red" }}><CloseIcon /></span>) : <span style={{ color: "grey" }}><PendingIcon /></span>}</TableCell>
                                          <TableCell sx={{ textAlign: "center" }}>{visitChange.traineeAccept !== null ? (visitChange.traineeAccept ? <span style={{ color: "green" }}><DoneIcon /></span> : <span style={{ color: "red" }}><CloseIcon /></span>) : <span style={{ color: "grey" }}><PendingIcon /></span>}</TableCell>
                                        </TableRow>
                                      )) : <TableRow><TableCell colSpan={7}><Typography style={{ textAlign: "center", color: "#949492" }}>No History Found</Typography></TableCell></TableRow>}
                                </TableBody>
                              </Table>
                            </TableContainer>

                          </Box>
                          {/* Result */}
                          <Box mb={1}>
                            <HourglassFullIcon fontSize="large" sx={{ marginRight: 1 }} color="primary" />
                            <Typography mt={1} mb={1} sx={{ display: "inline-block", fontSize: 20 }}>Result</Typography>

                            <Box sx={{ padding: "10px", backgroundColor: "#ffffff" }}>
                              <Typography>{visit.result ? visit.result : "No Results Found"}</Typography>
                            </Box>

                          </Box>
                          {/* File */}

                          <Box mb={1}>
                            <TextSnippetIcon fontSize="large" sx={{ marginRight: 1 }} color="primary" />
                            <Typography mt={1} mb={1} sx={{ display: 'inline-block', fontSize: 20 }}>File</Typography>
                            {/* list of files fixed in box */}
                            <Box sx={{ padding: "15px", backgroundColor: "#d4d4d4", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", overflowY: "scroll", height: "170px" }}>
                              {visit.organisationVisitDocuments.length > 0 ? visit.organisationVisitDocuments.map((doc: any) => (
                                // Inside
                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#ffffff", width: "100%", padding: 10, borderRadius: "10px", margin: "10px 0" }}>
                                  <Typography><TopicIcon sx={{ marginRight: 1, fontSize: "30px" }} color="primary" /> <span style={{ color: "#949492", fontSize: "20px" }}>|</span> &nbsp; Fiile 1</Typography>

                                  <Typography>Date...</Typography>

                                  <Button onClick={() => {
                                    if (doc.documentName.split(".")[1] !== "pdf") {
                                      window.open(constructImgUrl(currentVisit.id, doc.documentName))
                                    } else {
                                      groupStore.getUploadedFile(currentVisit.id, doc.documentName)
                                        .then((res) => {
                                          const file = new Blob([res], { type: "application/pdf" });
                                          const fileURL = URL.createObjectURL(file);
                                        })
                                    }
                                  }} >
                                    {doc.documentName}
                                  </Button>
                                </Box>
                              )) : <Typography>No Files</Typography>}
                            </Box>

                          </Box>
                        </Box>
                      </Card>
                    </div>
                  </td>
                </tr>
                <Box marginY={1} />
              </>
            ))
            : <tr><td className="text-center py-4" colSpan={7}>{t('NO_RESULT_FOUND')}</td></tr>}
        />
      </TabPanel>

      {/* Current List */}
      <TabPanel value={tabValue} index={1}>
        <VmTable
          loading={userStore.loading}
          page={currentPage}
          paginationCount={userStore.visitCurrentList.totalCount == 0 ? 1 : userStore.visitCurrentList.totalCount / PAGE_SIZE}
          onChangePagination={(event, pageNumber) => {
            setCurrentPage(pageNumber);
            userStore.getVisitList(false, PAGE_SIZE, (pageNumber - 1) * PAGE_SIZE);
          }}
          thead={["ID", t('VISIT_NAME'), t('START_TIME'), t('END_TIME'), "File Upload Status", "Check in " + t('STATUS'), t('ACTION')]}
          tbody={userStore.visitCurrentList.data.length > 0 ?
            userStore.visitCurrentList.data.map((visit, index) => (
              <>
                <Box marginY={1} />
                <tr key={`visit_${index}`}>
                  <td className={TD_FIRST}>{index + 1}</td>
                  <td className={TD_NORMAL}>{visit.name}</td>
                  <td className={TD_NORMAL}>{visit.startTime.split('T')[0] + " " + visit.startTime.split('T')[1]}</td>
                  <td className={TD_NORMAL}>{visit.endTime.split('T')[0] + " " + visit.endTime.split('T')[1]}</td>
                  <td className={TD_NORMAL}>
                    {visit.organisationVisitStudents.length > 0 &&
                      visit.organisationVisitStudents[0].organisationVisitDocuments.length > 0 ?
                      <Box sx={ITEM_PERFECT_INLINED} className="text-green-500"><FileDownloadDoneIcon /><Typography marginLeft={1}>Files Uploaded</Typography></Box>
                      : <Box sx={ITEM_PERFECT_INLINED} className="text-red-500"><FileDownloadOffIcon /><Typography marginLeft={1}>No File Uploaded</Typography></Box>}
                  </td>
                  <td className={TD_NORMAL}>
                    <span className={`${visit.checkInTime ? "bg-green-400" : "bg-orange-400"} rounded-2xl text-white p-2 pt-1`}>
                      <Typography variant="caption">{visit.checkInTime ? t('Checked in by Trainer') : t('Not Check in by Trainer')}</Typography>
                    </span>
                  </td>
                  <td className={TD_LAST}>
                    <Button
                      variant="outlined"
                      onClick={() => alert("1")}
                    >
                      {t('VIEW')}
                    </Button>
                  </td>
                </tr>

                <Box marginY={1} />
              </>
            ))
            : <tr><td className="text-center py-4" colSpan={7}>{t('NO_RESULT_FOUND')}</td></tr>}
        />
      </TabPanel>

      <VmModal
        openModal={showModal}
        onClose={handleCloseModal}
        width={500}
        title={t('RESCHEDULE')}
        showButton={false}
      >
        {currentVisit &&
          <>
            <TabPanel value={modalTabValue} index={0} paddingValue={0}>
              {currentVisit &&
                <>
                  <form onSubmit={createVisitPostHanlder}>
                    <FormGroup>
                      <FormControl>
                        <InputLabel htmlFor="locationId">Location</InputLabel>
                        {/* <Input style={{ marginBottom: "10px" }} placeholder={currentVisit.locationId} onChange={createVisitPropHandler} value={createVisitProp.locationId} name={"locationId"}></Input> */}
                        <Select
                          id="locationId"
                          value={currentVisit.locationId}
                          label="locationId"
                          onChange={createVisitPropHandler}
                          name={"locationId"}
                          style={{ marginBottom: "10px" }}
                        >
                          {toJS(userStore.organisationList.data).map((org: any) => (
                            <MenuItem value={org.id}>{org.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField style={{ marginBottom: "10px" }} id="startTime" label="startTime" type="datetime-local" defaultValue={currentVisit.finalStartTime} onChange={createVisitPropHandler} value={createVisitProp.startTime} name={"startTime"} />
                      <TextField style={{ marginBottom: "10px" }} id="endTime" label="endTime" type="datetime-local" defaultValue={currentVisit.finalEndTime} onChange={createVisitPropHandler} value={createVisitProp.endTime} name={"endTime"} />
                      <Button variant="outlined"
                        sx={{ margin: 2, float: "right" }} type="submit" >Submit</Button>
                    </FormGroup>
                  </form>
                </>
              }
         
            </TabPanel>
            <TabPanel value={modalTabValue} index={1} paddingValue={0}>
              {currentVisit.organisationVisitDocuments.length > 0 ?
                <div className="grid grid-cols-5 gap-4">
                  {currentVisit.organisationVisitDocuments.map((doc: any) => (
                    <button className="border hover:border-theme hover-border-2"
                      onClick={() => {
                        if (doc.documentName.split('.')[1] !== "pdf") {
                          window.open(constructImgUrl(currentVisit.id, doc.documentName));
                        } else {
                          userStore.getUploadedFile(currentVisit.id, doc.documentName)
                            .then((res) => {
                              const file = new Blob([res], { type: "application/pdf" });
                              const fileURL = URL.createObjectURL(file);
                              window.open(fileURL);
                            })
                        }
                      }}>
                      {doc.documentName.split('.')[1] !== "pdf" ?
                        <img src={constructImgUrl(currentVisit.id, doc.documentName)} />
                        : userStore.loadingFile ? <CircularProgress className="text-theme" />
                          : <>
                            <FilePresentIcon fontSize={"large"} />
                            <Typography variant="caption">PDF</Typography>
                          </>}

                    </button>
                  ))}
                </div>
                : <Typography textAlign="center">{t('NO_DOCUMENT_UPLOADED_IN_THIS_VISIT')}</Typography>}
            </TabPanel>
          </>}
      </VmModal>
    </ContentLayout>
  )
});

export default VisitPage;
